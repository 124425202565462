@import "global-override.less";
.title {
  color: @primary-color;
  margin-bottom: 32px;
  margin-top: 15vh;
}
.container {
  // background: red;
  height: 100vh;
  main {
    overflow-y: auto;
    padding-bottom: 80px;
  }
}

@primary-color: #005eff;@font-size-base: 12px;