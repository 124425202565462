@import "global-override.less";
.steps {
  .ant-steps-item.ant-steps-item-finish {
    .ant-steps-item-title {
      color: @primary-color !important;
    }
  }

  .ant-steps-item.ant-steps-item-process.ant-steps-item-active {
    .ant-steps-item-title {
      color: @primary-color;
    }
  }

  .ant-steps-item-title {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
  }
}

@primary-color: #005eff;@font-size-base: 12px;