@import "global-override.less";
.title {
  color: @primary-color;
  margin-bottom: 16p;
  margin-top: 15vh;
}
.container {
  height: 100vh;
  main {
    overflow-y: auto;
    padding-bottom: 80px;
  }
}

@primary-color: #005eff;@font-size-base: 12px;