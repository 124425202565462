@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";
@import "global-override.less";

html {
  // font-size: 62.5%;
  //white-space: pre !important;
}

/*viewport height === vh*/
html body #root .App {
  background-color: #f8faff;
  min-height: 100vh;

  .layout {
    background-color: #f8faff;
  }
}

#root {
  //white-space: pre !important;
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}

.upload-list-inline [class*="-upload-list-rtl"] .ant-upload-list-item {
  float: right;
}

.ant-input-group > .ant-input:last-child {
  border-left: none;
}

.ant-badge-status-dot {
  width: 10px;
  height: 10px;
}

.ant-table-thead > tr > th {
  // background-color: #ffffff;
}

.ant-modal-footer {
  text-align: left;
}

.ant-upload-list-picture-card-container {
  margin: 6px 6px 6px 0;

  .ant-upload-list-item-actions > a > span {
    margin-top: 2px !important;
  }
}

.sigCanvas {
  background: #ffffff;
  /* Text/text-200 */

  //margin-bottom: 20px;

  border: 1px solid #c4c8d0;
  border-radius: 4px;
}

.ant-modal-confirm {
  .ant-modal-content {
    border-radius: 4px;
    background: var(--neutrals-white, #fff);
    box-shadow: 0px -4px 0px 0px #005eff;
    .ant-modal-confirm-title {
      color: var(--text-text-600, #232b3a);
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 120% */
      letter-spacing: 0.2px;
    }
    .ant-modal-body {
      padding-top: 12px;
    }
    .ant-modal-confirm-content {
      margin: 32px 0;
      color: var(--text-text-400, #4e5a71);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.028px;
    }
    .ant-modal-confirm-btns {
      margin-top: 0;
      display: flex;
      gap: 8px;
      .ant-btn-default {
        order: 1;
      }
      .ant-btn-primary {
        order: 0;
        margin-left: 0;
      }
    }
  }
}
.ant-modal-title {
  color: #232b3a;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
  letter-spacing: 0.2px;
}

.shotDisplayText {
  --max-lines: 3;
  overflow: hidden;
  max-width: 500px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines);

}

#chatra.chatra--side-bottom {
  bottom: 50px !important;
}

@primary-color: #005eff;@font-size-base: 12px;